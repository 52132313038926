import React from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { makeStyles } from "@material-ui/core/styles";

const OrderToggle = (props: {
  alignment: any;
  handleAlignment: any;
  filter: any;
  resetFilter: any;
}) => {
  const classes = useStyles();
  let { alignment, handleAlignment, filter, resetFilter } = props;

  return (
    <div className={classes.buttons}>
      <ToggleButtonGroup
        size={"small"}
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
      >
        <ToggleButton
          className={classes.toggleButton}
          value="today"
          onClick={() => {
            filter("today");
          }}
        >
          TODAY
        </ToggleButton>
        <ToggleButton
          className={classes.toggleButton}
          value="tomorrow"
          onClick={() => {
            filter("tomorrow");
          }}
        >
          TOMORROW
        </ToggleButton>
        <ToggleButton
          className={classes.toggleButton}
          value="two"
          onClick={() => {
            filter("twodays");
          }}
        >
          +2
        </ToggleButton>
        <ToggleButton
          className={classes.toggleButton}
          value="reset"
          onClick={() => {
            resetFilter();
          }}
        >
          All
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default OrderToggle;

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  toggleButton: {
    "&.MuiToggleButton-root.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
    },
    "&.MuiToggleButton-root:hover": {
      backgroundColor: "rgb(250, 200, 192, 0.2)",
    },
  },
}));
