import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles } from '@material-ui/core/styles';

interface SpinnerProps {
  size: undefined | number | string;
}

const Spinner = (props: SpinnerProps) => {
  const { size } = props;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CircularProgress color="primary" size={size} />
    </div>
  );
};

Spinner.defaultProps = {
  size: undefined,
};

export default Spinner;

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      textAlign: 'center',
      margin: 20,
    },
  }),
);
