import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './AuthProvider';
import Spinner from './components/Spinner';

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  // Check why authentication doesnt persist and user logs out on refresh
  const { authenticated, loadingAuthState } = useContext(AuthContext);

  if (loadingAuthState) {
    return <Spinner size={30} />;
  }

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        authenticated ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { prevPath: rest.path } }} />
        )
      }
    />
  );
};

export default PrivateRoute;
