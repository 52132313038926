import React from "react";
import Main from "../components/Main";
import Login from "../components/Login";
import PrivateRoute from "../PrivateRoute";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const ApplicationRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <PrivateRoute exact path="/" component={Main} />
      </Switch>
    </Router>
  );
};

export default ApplicationRoutes;
