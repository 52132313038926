import React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { render } from "react-snapshot";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { AuthProvider } from "./AuthProvider";
import { LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import "./index.css";
import "./assets/fonts/Lato-Regular.ttf";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fac8c0",
    },
    secondary: {
      main: "#4e656d",
    },
  },
  typography: {
    fontFamily: ["Lato", "Roboto"].join(","),
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

render(
  <React.StrictMode>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={DateFnsUtils}>
          <App />
        </LocalizationProvider>
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
