import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import Dashboard from "../Dashboard";
import Compact from "../Compact";
import firebase from "../../firebase";
import SideMenu from "./sideMenu";
import Footer from "../Footer";
import { useHistory } from "react-router-dom";
import { transformWoocommerceToInternal } from "../utils/transformer";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import "firebase/auth";
import "firebase/firestore";

const drawerWidth = 220;

export const formatDate = (date: Date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const Main = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([{} as any]);
  const [displayedOrders, setDisplayedOrders] = useState([{} as any]);
  const [selectedDashboard, setSelectedDashboard] = useState("COMPACT");
  const [alignment, setAlignment] = useState("today");
  useEffect(() => {
    setLoading(true);
    firebase
      .firestore()
      .collection("kitsuneOrders")
      .orderBy("date_created", "desc")
      .onSnapshot(
        (
          querySnapshots: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
        ) => {
          let allOrders: any = [];
          querySnapshots.forEach(
            (
              snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
            ) => {
              let data: any = snapshot.data();
              if (!data.webhook_id)
                allOrders.push({ ...data, uid: snapshot.ref.id });
            }
          );
          let transformedOrders = transformWoocommerceToInternal(allOrders);
          transformedOrders = transformedOrders.sort(
            (x: any, y: any) =>
              new Date(x.deliveryDate).getTime() -
              new Date(y.deliveryDate).getTime()
          );
          setOrders(transformedOrders);
          setDisplayedOrders(
            transformedOrders.filter((x: any) => {
              let deliveryDate = formatDate(x.deliveryDate);
              return deliveryDate === formatDate(new Date());
            })
          );
          setLoading(false);
        }
      );
  }, []);
  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        history.push("/");
      });
  };
  const filter = (type: string) => {
    let filteredOrders = [];
    let timeDelta = 0;
    if (type === "tomorrow") {
      timeDelta = 86400000;
    } else if (type === "twodays") {
      timeDelta = 86400000 * 2;
    }
    filteredOrders = orders.filter((x: any) => {
      const dateForFilter = new Date(new Date().getTime() + timeDelta);
      let deliveryDate = formatDate(x.deliveryDate);
      return deliveryDate === formatDate(dateForFilter);
    });
    setDisplayedOrders(filteredOrders);
  };

  const resetFilter = () => {
    setDisplayedOrders(orders);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const selectDashboard = (dashboard: string) => {
    setSelectedDashboard(dashboard);
    if (mobileOpen) handleDrawerToggle();
  };
  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classes.appBar}
          color="inherit"
          elevation={1}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Kitsune Keki
            </Typography>
            <IconButton color="inherit" onClick={logout}>
              <PowerSettingsNewOutlinedIcon color="primary" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <SideMenu
                selectedDashboard={selectedDashboard}
                selectDashboard={selectDashboard}
              />
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <SideMenu
                selectedDashboard={selectedDashboard}
                selectDashboard={selectDashboard}
              />
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              {selectedDashboard === "DASHBOARD" && (
                <Dashboard
                  orders={displayedOrders}
                  loading={loading}
                  filter={filter}
                  resetFilter={resetFilter}
                  alignment={alignment}
                  setAlignment={setAlignment}
                />
              )}
              {selectedDashboard === "COMPACT" && (
                <Compact
                  orders={displayedOrders}
                  loading={loading}
                  filter={filter}
                  resetFilter={resetFilter}
                  alignment={alignment}
                  setAlignment={setAlignment}
                />
              )}
            </Grid>
            <Box pt={4}>
              <Footer />
            </Box>
          </Container>
        </main>
      </div>
    </>
  );
};

export default Main;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    listItem: {
      "&.Mui-selected": {
        backgroundColor: "blue",
        color: "white",
      },
      "&.Mui-selected:hover": {
        backgroundColor: "blue",
        color: "white",
      },
      "&:hover": {
        backgroundColor: "blue",
        color: "white",
      },
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    title: {
      flexGrow: 1,
    },
    paper: {
      marginTop: theme.spacing(2),
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  })
);
