import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import OrderToggle from "../OrderToggle";
import Spinner from "../Spinner";
import { makeStyles } from "@material-ui/core/styles";

const Compact = (props: {
  orders: any;
  loading: boolean;
  filter: (type: string) => void;
  resetFilter: () => void;
  alignment: string;
  setAlignment: any;
}) => {
  const classes = useStyles();
  let { orders, loading, resetFilter, filter, alignment, setAlignment } = props;
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAlignment = (event: any, newAlignment: any) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const formatDate = (date: Date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month].join("/");
  };

  return (
    <>
      <Container component="main" maxWidth="lg" className={classes.container}>
        <CssBaseline />
        {loading ? (
          <Spinner size={25} />
        ) : (
          <div className={classes.paper}>
            <OrderToggle
              alignment={alignment}
              handleAlignment={handleAlignment}
              filter={filter}
              resetFilter={resetFilter}
            />
            {orders &&
              orders.length > 0 &&
              orders.map((order: any) => (
                <Accordion
                  elevation={0}
                  key={order.id}
                  expanded={expanded === order.id}
                  onChange={handleChange(order.id)}
                >
                  <AccordionSummary aria-controls={order.id} id={order.id} style={order.status === 'failed' ? {color: 'red'} : {color: 'black'}}>
                    <div className={classes.accordionContent}>
                      <Typography variant="caption">
                        <span className={classes.orderHeader}>{order.id}</span>
                        {" · "}
                        <span className={classes.orderHeader}>
                          {" "}
                          {formatDate(order.deliveryDate)}
                        </span>
                        {" · "}
                        <span className={classes.orderHeader}>
                          {order.type}
                        </span>
                        {order?.type?.includes("Specific") ? (
                          <span className={classes.orderHeader}>
                            {" · " + order.deliveryTime}
                          </span>
                        ) : (
                          ""
                        )}
                      </Typography>
                      <Typography variant="body2">
                        {order.customerName} · {order.customerNumber}
                        {order.candles === "Yes" ? " · Candles" : ""}
                        {order.birthdayTopper === "Yes" ? " · Bday" : ""}
                      </Typography>
                      <Typography variant="body2">
                        {order?.type?.includes("Delivery") ? order.address : ""}
                      </Typography>
                      {typeof(order?.formattedLineItems) === "string" && order?.formattedLineItems}
                      {typeof(order?.formattedLineItems) !== "string" && order?.formattedLineItems?.map((lineItem: any) => {
                        return (
                          <Typography
                            variant="body2"
                            className={classes.orderData}
                          >
                            {lineItem}
                          </Typography>
                        );
                      })}

                      {order?.comments !== "" && (
                        <Typography
                          variant="body2"
                          className={classes.comments}
                        >
                          {order?.comments}
                        </Typography>
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.lineItems}>
                      {order.orderString}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
          </div>
        )}
      </Container>
    </>
  );
};

export default Compact;

const useStyles = makeStyles((theme) => ({
  comments: {
    borderLeft: "2px solid #4e656d",
    paddingLeft: "5px",
  },
  container: {
    margin: 0,
    padding: 0,
  },
  orderHeader: {
    color: "#4e656d",
  },
  paper: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  accordionContent: {
    flexDirection: "column",
  },
  orderData: {
    borderLeft: "2px solid #fac8c0",
    paddingLeft: "5px",
  },
  lineItems: {
    whiteSpace: "pre-wrap",
    fontSize: "10px",
  },
}));
