import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import MaterialTable from "material-table";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import tableIcons from "../TableIcons";
import { makeStyles } from "@material-ui/core/styles";
import { DateRangePicker, DateRangeDelimiter } from "@material-ui/pickers";
import OrderToggle from "../OrderToggle";
import PatchedPagination from "../PatchedPagination";
import firebase from "../../firebase";
import "firebase/firestore";
import { formatDate } from "../Main";

const Dashboard = (props: {
  orders: any;
  loading: boolean;
  filter: (type: string) => void;
  resetFilter: () => void;
  alignment: string;
  setAlignment: any;
}) => {
  const classes = useStyles();
  let { orders, loading, resetFilter, filter, alignment, setAlignment } = props;
  const [open, setOpen] = useState(false);
  const [displayedOrders, setDisplayedOrders] = useState([]);
  const [message, setMessage] = useState("");
  const [value, setValue] = React.useState([null, null] as any);

  const handleAlignment = (event: any, newAlignment: any) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  useEffect(() => {
    setDisplayedOrders(orders);
  }, [orders]);

  return (
    <>
      <Container component="main" maxWidth="lg" className={classes.container}>
        <CssBaseline />
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          onClose={() => setOpen(false)}
          message={message}
        />
        <div className={classes.paper}>
          <OrderToggle
            alignment={alignment}
            handleAlignment={handleAlignment}
            filter={filter}
            resetFilter={resetFilter}
          />
          {alignment === "reset" && (
            <DateRangePicker
              startText="Start Date"
              endText="End Date"
              value={value}
              onChange={(newValue: any) => {
                const startTime = new Date(newValue[0]).getTime();
                const endTime = new Date(newValue[1]).getTime();
                if (newValue[0] && newValue[1]) {
                  const filteredOrders = orders.filter((order: any) => {
                    const deliveryTime = new Date(
                      formatDate(order.deliveryDate) + "T00:00:00"
                    ).getTime();
                    if (newValue[0] === newValue[1])
                      return deliveryTime === startTime;
                    return deliveryTime >= startTime && deliveryTime <= endTime;
                  });
                  setDisplayedOrders(filteredOrders);
                }
                setValue(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <div className={classes.dateRange}>
                  <TextField size="small" {...startProps} />
                  <DateRangeDelimiter> to </DateRangeDelimiter>
                  <TextField size="small" {...endProps} />
                </div>
              )}
            />
          )}
          <MaterialTable
            isLoading={loading}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
              Pagination: PatchedPagination,
            }}
            icons={tableIcons as any}
            title="Kitsune Keki Orders"
            options={{
              padding: "dense",
              pageSize: 10,
              rowStyle: (rowData: any, idx: number) => ({
                backgroundColor:
                  idx % 2 === 0 ? "rgb(250, 200, 192, 0.4)" : "white",
                color: rowData.status === 'failed' ? 'red' : 'black',
              }),
              exportButton: {
                csv: true,
                pdf: true,
              },
            }}
            columns={[
              {
                title: "ID",
                field: "id",
                type: "numeric",
                editable: "never",
              },
              {
                title: "Order Type",
                field: "type",
                type: "string",
              },
              {
                title: "C. Name",
                field: "customerName",
                type: "string",
              },
              {
                title: "C. Number",
                field: "customerNumber",
                type: "string",
              },
              {
                title: "R. Name",
                field: "recipientName",
                type: "string",
              },
              {
                title: "R. Number",
                field: "recipientNumber",
                type: "string",
              },
              {
                title: "Address",
                field: "address",
                type: "string",
              },
              {
                title: "Delivery Date",
                field: "deliveryDate",
                type: "date",
              },
              {
                title: "Delivery Time",
                field: "deliveryTime",
                type: "string",
              },
              {
                title: "Order",
                field: "formattedLineItemsString",
                type: "string",
              },
              {
                title: "Bday",
                field: "birthdayTopper",
                type: "string",
                editable: "never",
              },
              {
                title: "Candles",
                field: "candles",
                type: "string",
                editable: "never",
              },
              {
                title: "Note",
                field: "customerNote",
                type: "string",
                editable: "never",
              },
              {
                title: "Comments",
                field: "comments",
                type: "string",
              },
              {
                title: "Status",
                field: "status",
                type: "string",
              },
            ]}
            detailPanel={(rowData: any) => {
              return (
                <div className={classes.lineItems}>{rowData.orderString}</div>
              );
            }}
            editable={{
              onRowUpdate: (newData: any, oldData: any) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    let metadataObject = oldData.metadataObject;
                    const dateIdx = oldData.metadataObject.findIndex(
                      (x: any) => {
                        return x.key === "Collection/Delivery Date";
                      }
                    );
                    const newDeliveryDate = new Date(newData.deliveryDate);
                    metadataObject[dateIdx]["value"] =
                      newDeliveryDate.toLocaleString("default", {
                        day: "numeric",
                        month: "long",
                      }) +
                      ", " +
                      newDeliveryDate.getFullYear();
                    firebase
                      .firestore()
                      .collection("kitsuneOrders")
                      .doc(newData.uid.toString())
                      .update({
                        customerName: newData.customerName,
                        customerNumber: newData.customerNumber,
                        recipientName: newData.recipientName,
                        recipientNumber: newData.recipientNumber,
                        modifiedAddress: newData.address || "",
                        order: newData.formattedLineItemsString || "",
                        shippingTitle: newData.type,
                        comments: newData.comments || "",
                        delivery_time: { delivery_time: newData.deliveryTime },
                        meta_data: metadataObject,
                      })
                      .then(() => {
                        setMessage("Update success");
                        setOpen(true);
                        resolve("");
                      })
                      .catch(() => {
                        setMessage("Something went wrong, please try again");
                        setOpen(true);
                        reject("");
                      });
                    resolve("");
                  }, 1000);
                }),
            }}
            data={displayedOrders}
          />
        </div>
      </Container>
    </>
  );
};

export default Dashboard;

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 0,
    padding: 0,
  },
  dateRange: {
    display: "flex",
    margin: "20px auto 20px auto",
  },
  paper: {
    marginTop: theme.spacing(2),
  },
  lineItems: {
    margin: theme.spacing(3),
    whiteSpace: "pre-wrap",
  },
}));
