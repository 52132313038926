import React, { useContext, useState, FormEvent } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import firebase from "../../firebase";
import logo from "../../assets/img/logo.png";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../AuthProvider";
import { useHistory } from "react-router-dom";
import Spinner from "../Spinner";

const SignIn = () => {
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [state, updateState] = useState({
    email: null as string | null,
    password: null as string | null,
  });
  const login = (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(
        state.email as string,
        state.password as string
      )
      .then((userCredential: firebase.auth.UserCredential) => {
        authContext.setUser(userCredential);
        setTimeout(() => {
          setLoading(false);
          history.push("/");
        }, 100);
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Wrong email or password");
        setOpen(true);
      });
  };
  const updateForm = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    updateState({
      ...state,
      [name]: value,
    });
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={3000}
        message={message}
      />
      <div className={classes.paper}>
        <Avatar className={classes.avatar} src={logo} />
        <Typography component="h1" variant="h6">
          Kitsune Keki
        </Typography>
        <form className={classes.form} onSubmit={login}>
          <TextField
            required
            fullWidth
            variant="outlined"
            margin="normal"
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={updateForm}
          />
          <TextField
            required
            fullWidth
            variant="outlined"
            margin="normal"
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={updateForm}
          />
          {loading ? (
            <Spinner size={30} />
          ) : (
            <Button
              disableElevation
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
          )}
        </form>
      </div>
    </Container>
  );
};

export default SignIn;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
