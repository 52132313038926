const skuMapper: any = {
  "basque-cheesecake": "Basque",
  "hanabi-charlotte": "Hanabi",
  "murasaki-charlotte": "Murasaki",
  "tsubaki-charlotte": "Tsubaki",
  "shiawase-charlotte": "Shiawase",
  taruto: "Taruto",
  "nama-choco": "Nama-choco",
};

const tarutoMapper: { [key: string]: string } = {
  banofee: "Banofee",
  "dark-choco": "Dark Choco",
  "lemon-meringue": "Lemon Meringue",
  "matcha-white-choco": "Matcha w-Choco",
};

export const transformWoocommerceToInternal = (data: any) => {
  let transformedOrders = data
    .map((x: any) => {
      if (x.id === 1039) {
        console.log(x);
      }
      let metadataObject = Object.assign(
        {},
        ...x.meta_data.map((x: any) => ({ [x.key]: x.value }))
      );
      let shippingStatus = x.shipping_lines[0].method_id;
      let shippingTitle = x.shipping_lines[0].method_title;
      let orderType = "";
      if (shippingStatus === "local_pickup") {
        orderType = "Collection";
      } else if (shippingTitle.includes("Multiple")) {
        orderType = "Delivery (Multiple)";
      } else if (shippingTitle.includes("Premium")) {
        orderType = "Delivery (Specific time)";
      } else {
        orderType = "Delivery";
      }
      const formattedLineItems = x.line_items.map((item: any, idx: number) => {
        const itemMetadata = Object.assign(
          {},
          ...item.meta_data.map((x: any) => ({ [x.key]: x.value }))
        );
        return [
          "[ ",
          skuMapper[item.sku],
          Object.keys(itemMetadata)
            .map((x: any) => {
              if (x === "pa_size") {
                return " · " + itemMetadata[x].replace("-inches", "''");
              } else if (x === "pa_gift-box") {
                return itemMetadata[x] === "yes" ? " · Gift Box" : "";
              } else if (x === "pa_heart-shaped-candle") {
                return itemMetadata[x] === "yes" ? " · Heart Candle" : "";
              } else if (x === "pa_kyoho-grape") {
                return itemMetadata[x] === "yes" ? " · Kyoho Grapes" : "";
              } else if (x === "pa_customised-letters") {
                return itemMetadata[x] === "yes"
                  ? ` · Letters: ${itemMetadata["customised_letters"]}`
                  : "";
              } else if (x === "customised_letters") {
                return itemMetadata[x] === "no"
                  ? ` · Letters: ${itemMetadata["customised_letters"]} - customer didn't pay`
                  : "";
              } else if (x === "pa_taruto-1-2") {
                return ` · Taruto 1 & 2: ${
                  tarutoMapper[itemMetadata["pa_taruto-1-2"]]
                }`;
              } else if (x === "pa_taruto-3-4") {
                return ` · Taruto 3 & 4: ${
                  tarutoMapper[itemMetadata["pa_taruto-3-4"]]
                }`;
              } else if (x === "pa_taruto-5-6-14") {
                return ` · Taruto 5 & 6: ${
                  tarutoMapper[itemMetadata["pa_taruto-5-6-14"]]
                }`;
              }
            })
            .join("") +
            " ] x " +
            item.quantity,
        ].join("");
      });
      const formattedLineItemsString = formattedLineItems.join(", ");
      let deliveryTime =
        x.delivery_time.delivery_time !== ""
          ? x.delivery_time.delivery_time
          : "None";
      const fullAddress =
        x.shipping.address_1 +
        ", " +
        x.shipping.address_2 +
        ", " +
        x.shipping.postcode;
      const orderString = constructOrderString(
        x,
        orderType,
        deliveryTime,
        fullAddress,
        metadataObject
      );
      const deliveryDate = new Date(metadataObject["Collection/Delivery Date"]);
      const formattedDeliveryDate =
        deliveryDate.toLocaleString("default", {
          day: "numeric",
          month: "long",
        }) +
        ", " +
        deliveryDate.getFullYear();
      return {
        formattedLineItems: x.order || formattedLineItems,
        formattedLineItemsString: x.order || formattedLineItemsString,
        uid: x.uid,
        id: x.id,
        birthdayTopper: metadataObject.birthday_topper,
        comments: x.comments || "",
        candles: metadataObject.candles,
        status: x.status,
        type: x.shippingTitle || orderType,
        customerName:
          x.customerName || x.billing.first_name + " " + x.billing.last_name,
        customerNumber: x.customerNumber || x.billing.phone.replaceAll(" ", ""),
        recipientName:
          x.recipientName || x.shipping.first_name + " " + x.shipping.last_name,
        recipientNumber: x.recipientNumber || x.shipping?.shipping_number || "",
        address:
          x.modifiedAddress ||
          x.shipping.address_1 +
            ", " +
            x.shipping.address_2 +
            ", " +
            x.shipping.postcode,
        deliveryDate: formattedDeliveryDate,
        deliveryTime: deliveryTime,
        customerNote: x.customer_note,
        lineItems: x.line_items,
        totalPrice: x.total,
        orderString: orderString,
        metadataObject: x.meta_data,
      };
    });
  return transformedOrders;
};

const constructOrderString = (
  x: any,
  orderType: string,
  deliveryTime: string,
  fullAddress: string,
  metadataObject: any
) => {
  let multipleAddressesString = "";
  if (
    !metadataObject._thwcfe_disabled_sections.includes("multiple_addresses")
  ) {
    const count = metadataObject._thwcfe_repeat_sections.split(":").length;
    multipleAddressesString +=
      "\nName 1: " +
      metadataObject["name"] +
      "\nNumber 1: " +
      metadataObject["number"] +
      "\nCake 1: " +
      metadataObject["cake"] +
      "\nAddress 1: " +
      metadataObject["multiple_address"];
    for (let i = 2; i < count + 1; i++) {
      multipleAddressesString +=
        `\n\nName ${i}: ` +
        metadataObject["name_" + i] +
        `\nNumber ${i}: ` +
        metadataObject["number_" + i] +
        `\nCake ${i}: ` +
        metadataObject["cake_" + i] +
        `\nAddress ${i}: ` +
        metadataObject["multiple_address_" + i];
    }
  }
  let orderString = `--- Order ID: ${x.id} ---\n\n`;
  orderString += `Order Type: ${orderType}\n\n`;
  orderString += `Date: ${metadataObject["Collection/Delivery Date"]}\n`;
  orderString += `Time: ${
    orderType === "Delivery (Specific time)" ? deliveryTime : "None"
  }\n`;
  orderString += `Address: ${fullAddress}\n\n`;
  x.line_items.forEach((item: any) => {
    orderString += ` - ${item.name} x ${item.quantity}: $${
      item.price * item.quantity
    }\n`;
    if (item.meta_data.length > 0) {
      const all_meta_data_values = item.meta_data
        .map((x: any) => `${x.display_key}` + ": " + x.display_value)
        .join(", ");
      orderString += `\t> ${all_meta_data_values}\n\n`;
    } else {
      orderString += "\n";
    }
  });
  orderString += `Total price: $${x.total}`;
  if (multipleAddressesString !== "")
    orderString += `\n\nMultiple addresses:\n${multipleAddressesString}`;
  if (x.customer_note !== "") orderString += `\n\nNote: ${x.customer_note}`;
  return orderString;
};
