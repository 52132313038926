import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Avatar from "@material-ui/core/Avatar";
import logo from "../../assets/img/logo.png";
import {
  makeStyles,
} from "@material-ui/core/styles";

const Footer = () => {
  const classes = useStyles();
  return (
    <>
      <Avatar className={classes.avatar} src={logo} />
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://kitsunekeki.com" target="_blank">
          Kitsune Keki
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </>
  );
};

export default Footer;


const useStyles = makeStyles(() => ({
  avatar: {
    margin: "0 auto 10px auto",
  }
}));
