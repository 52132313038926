import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import NotesOutlinedIcon from '@material-ui/icons/NotesOutlined';
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const SideMenu = (props: {
  selectedDashboard: string;
  selectDashboard: (dashboard: string) => void;
}) => {
  const classes = useStyles();
  const { selectedDashboard, selectDashboard } = props;
  return (
    <>
      <div className={classes.toolbar} />
      <Divider />
      <List disablePadding>
        <div>
          <ListItem
            button
            selected={selectedDashboard === "COMPACT"}
            onClick={() => {
              selectDashboard("COMPACT");
            }}
          >
            <ListItemIcon>
              <NotesOutlinedIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText primary="Summary" />
          </ListItem>
          <ListItem
            selected={selectedDashboard === "DASHBOARD"}
            button
            onClick={() => {
              selectDashboard("DASHBOARD");
            }}
          >
            <ListItemIcon>
              <HomeOutlinedIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </div>
      </List>
    </>
  );
};

export default SideMenu;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: theme.mixins.toolbar,
  })
);
